<template>
  <div
    v-if="!readMore"
    class="tracking-wider prose prose-strong:text-brandRed"
    :class="[
      columnsMobile,
      columnsDesktop,
      fontSizeMobile,
      fontSizeDesktop,
      alignMobile,
      alignDesktop,
      paddingHorizontal,
      desktopPaddingHorizontal,
      mobileMarginBottom,
      desktopMarginBottom,
      mobileMarginTop,
      desktopMarginTop,
      maxWidthDesktop,
      maxWidth,
      color,
    ]"
    v-html="content.html"
  />
  <div v-else>
    <div
      class="relative tracking-wider prose prose-strong:text-brandRed"
      :class="[
        columnsMobile,
        columnsDesktop,
        fontSizeMobile,
        fontSizeDesktop,
        alignMobile,
        alignDesktop,
        paddingHorizontal,
        desktopPaddingHorizontal,
        mobileMarginBottom,
        desktopMarginBottom,
        mobileMarginTop,
        desktopMarginTop,
        maxWidthDesktop,
        maxWidth,
        color,
        {
          'max-h-16 overflow-hidden scrollbar-hide before:w-full before:h-full before:bg-gradient-to-t before:from-white before:via-transparent before:absolute':
            !showMore && needsReadMore,
        },
      ]"
      v-html="content.html"
    />
    <button
      v-if="needsReadMore"
      class="mt-4 text-sm tracking-wider underline underline-offset-4"
      @click="() => (showMore = !showMore)"
    >
      {{ showMore ? $t('Read_Less') : $t('Read_More') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TextComponent',
  props: {
    content: {
      type: Object,
      required: false,
      default: () => {},
    },
    fontSizeMobile: {
      type: String,
      required: false,
      default: 'text-base',
    },
    fontSizeDesktop: {
      type: String,
      required: false,
      default: 'text-base',
    },
    columnsMobile: {
      type: String,
      required: false,
      default: 'col-span-4',
    },
    mobileMarginBottom: {
      type: String,
      required: false,
      default: 'mb-0',
    },
    mobileMarginTop: {
      type: String,
      required: false,
      default: 'mt-0',
    },
    desktopMarginBottom: {
      type: String,
      required: false,
      default: 'lg:mb-0',
    },
    paddingHorizontal: {
      type: String,
      required: false,
      default: 'px-2',
    },
    desktopPaddingHorizontal: {
      type: String,
      required: false,
      default: 'px-4',
    },
    desktopMarginTop: {
      type: String,
      required: false,
      default: 'lg:mt-0',
    },
    columnsDesktop: {
      type: String,
      required: false,
      default: 'lg:col-span-12',
    },
    alignMobile: {
      type: String,
      required: false,
      default: 'text-left',
    },
    maxWidthDesktop: {
      type: String,
      required: false,
      default: 'lg:max-w-auto',
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'max-w-auto',
    },
    alignDesktop: {
      type: String,
      required: false,
      default: 'lg:text-left',
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    readMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showMore: false,
  }),
  computed: {
    needsReadMore: ({ content }) => content.original.length > 200,
  },
}
</script>

<style lang="scss" scoped>
.text {
  ::v-deep ul,
  ::v-deep ol {
    list-style-position: inside;
  }

  ::v-deep ul {
    list-style-type: disc;
  }

  ::v-deep ol {
    list-style-type: decimal;
  }
}
</style>
